<template>
    <v-app>
        <v-container class="d-flex justify-center align-center fill-height">
            <v-card class="pa-5" width="400">
                <v-card-title class="text-h5">{{ isLoginMode ? 'Login' : 'Register' }}</v-card-title>
                <v-card-text>
                    <v-form ref="loginForm" v-model="valid" lazy-validation>
                        <!-- Email Input -->
                        <v-text-field
                                v-model="email"
                                label="Email"
                                :rules="emailRules"
                                required
                                type="email"
                                ></v-text-field>

                        <!-- Password Input -->
                        <v-text-field
                                v-model="password"
                                label="Password"
                                :rules="passwordRules"
                                required
                                type="password"
                                ></v-text-field>
                    </v-form>
                    <v-text-field
                            v-if="!isLoginMode"
                            v-model="confirmPassword"
                            label="Confirm Password"
                            :rules="confirmPasswordRules"
                            required
                            type="password"
                            ></v-text-field>

                    <!-- Error message for login failure -->
                    <v-alert
                            v-if="errorMessage"
                            type="error"
                            dismissible
                            class="mt-3"
                            >
                            {{ errorMessage }}
                    </v-alert>
                </v-card-text>

                <v-card-actions>
                    <v-btn
                            color="primary"
                            @click="isLoginMode ? loginFirebase() : registerUserFirebase()"
                            :disabled="!valid"
                            >
                            {{ isLoginMode ? 'Login' : 'Register' }}
                    </v-btn>
                        <v-spacer></v-spacer>
                        <!-- Toggle between Login and Register -->
                        <v-btn text @click="toggleAuthMode">
                            {{ isLoginMode ? 'Create Account' : 'Already have account' }}
                        </v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
    </v-app>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { useFirebaseAuth } from 'vuefire';
import router from '../router';


const email = ref('');
const password = ref('');
const confirmPassword = ref('');
const valid = ref(false);
const errorMessage = ref('');
const isLoginMode = ref(true);
const emailRules = [ v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid', ];
const passwordRules = [ v => !!v || 'Password is required', v => v.length >= 6 || 'Password must be at least 6 characters', ];
const confirmPasswordRules = [ v => !!v || 'Confirm Password is required', v => v === this.password || 'Passwords must match', ];

const auth = useFirebaseAuth();

async function loginFirebase() {
    signInWithEmailAndPassword(auth, email.value, password.value)
        .then(() => {
            router.push("/dashboard");
        })
        .catch((error) => {
            const errorCode = error.code;
            errorMessage.value = error.message;
            console.log(errorCode);
            console.log(errorMessage.value);
        });
};

async function registerUserFirebase() {
    createUserWithEmailAndPassword(auth, email.value, password.value)
        .then((userCredential) => {
            const user = userCredential.user;
            console.log(email.value);
            console.log("Registration completed");
            router.push("/dashboard");
        })
        .catch((error) => {
            const errorCode = error.code;
            errorMessage.value = error.message;
            console.log(errorCode);
            console.log(errorMessage.value);
        });
};

async function toggleAuthMode() {
  // Toggle between Login and Register modes
  isLoginMode.value = !isLoginMode.value;
  console.log("toggleAuthMode: " + isLoginMode.value);
  errorMessage.value = ''; // Clear error messages when switching modes
}

</script>

<style scoped>
</style>
