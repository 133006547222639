import { createApp, watch } from 'vue'
import { VueFire, VueFireAuth, useCurrentUser, useFirebaseAuth } from 'vuefire'
import { useRoute, useRouter } from "vue-router";
import { firebaseApp } from './firebase'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import App from './App.vue'
import router from './router'

import './style.css'

const app = createApp(App);

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
    },
    theme: {
        defaultTheme: 'light',
    },
});

app.use(VueFire, {
  firebaseApp,
  modules: [
    VueFireAuth(),
  ],
});


const user = useCurrentUser()
app.use(vuetify);
app.use(router).mount('#app');
