import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/LoginView.vue";
import { getAuth } from "firebase/auth";
import { useCurrentUser, useFirebaseAuth, getCurrentUser } from 'vuefire'

import viewerView from "/views/ViewerView.vue";
import dashboardView from "/views/DashboardView.vue";

const user = useCurrentUser()

const routes = [
    {
        path: "/",
        name: "login",
        component: LoginView,
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: dashboardView,
        meta: {
            authRequired: true,
        },
    },
    {
        path: "/viewer/:projectName",
        name: "viewer",
        component: viewerView,
        meta: {
            authRequired: true,
        },
    },
];

const router = createRouter({
    //history: createWebHistory(import.meta.env.BASE_URL),
    mode: "history",
    history: createWebHistory(),
    routes,
});

router.beforeEach( async (to, from, next) => {
    const auth = getAuth();
    const currentUser = await getCurrentUser()
    if (to.matched.some((record) => record.meta.authRequired)) {
        if (auth.currentUser) {
            if (to.name === "viewer" && !to.params.projectName) {
                router.push("/dashboard");
            }
            else {
                next();
            }
        } else {
            alert("You be logged in to access this area");
            router.push("/");
        }
    } else {
        if (to.name === "login" && auth.currentUser) {
            router.push("/dashboard");
        }
        else {
            next();
        }
    }
});

export default router;
