import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyA3DAZzg5TlG0-51lk2xRkQI71-ATAoRWI",
  authDomain: "tttest-6112f.firebaseapp.com",
  projectId: "tttest-6112f",
  storageBucket: "tttest-6112f.appspot.com",
  messagingSenderId: "1000592426400",
  appId: "1:1000592426400:web:9dc63bf45a6204dc5f90c5",
  measurementId: "G-C1NFG7SS6M"
};

export const firebaseApp = initializeApp(firebaseConfig);
