<template>
    <v-layout class="d-flex align-center justify-center rounded rounded-md">
        <v-container class="d-flex justify-center align-center fill-height">
            <v-app-bar app density="compact">
                <v-app-bar-nav-icon icon="mdi-tunnel" />
                    <v-app-bar-title class="font-weight-medium">Tunnel Twin</v-app-bar-title>
                    <v-spacer></v-spacer>
                    <v-menu offset-y>
                        <template v-slot:activator="{ props }">
                            <v-btn v-bind="props" text>
                                <!-- <v-avatar size="32" class="mr-2"> -->
                                <!-- <img src="mdi-dots-vertical" alt="User Avatar" /> -->
                                <!-- </v-avatar> -->
                                {{ email }}
                                <v-icon right>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item title="Profile" value="Profile" @click="onProfile" density="compact" prepend-icon="mdi-account-circle"/>
                                <v-list-item title="Logout" value="Logout" @click="onLogout" density="compact" prepend-icon="mdi-logout"/>
                                </v-list>
                    </v-menu>
                </v-app-bar>
                <v-main class="d-flex align-center justify-center" style="min-height: 300px; max-width: 800px;">
                    <v-container>
                        <v-row>
                            <v-col v-for="(project, index) in projects" cols="12">
                                <v-card
                                        :title="`${project.meta.name}`"
                                        :subtitle="`${project.meta.subtitle}`"
                                        :text="`${project.meta.description}`"
                                        @click="selectProject(index)"
                                        ></v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-main>
        </v-container>
    </v-layout>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useCurrentUser, useFirestore, useFirebaseAuth, useCollection } from 'vuefire';
import { signOut } from '@firebase/auth';
import { collection, query, getDocs, onSnapshot } from '@firebase/firestore';
import { mdiTunnel } from '@mdi/js';
import { onProfile, onLogout } from '/components/ttUtils';
import router from '../router';

const user = useCurrentUser();
const auth = useFirebaseAuth();
const email = auth?.currentUser?.email;
const db = useFirestore();

const projects = useCollection(collection(db, 'projects'));

async function selectProject(index: number) {
    router.push("/viewer/" + projects.value[index].id);
};

</script>

<style scoped>
</style>
