import * as THREE from 'three';

export class ttMetashapeSensor {
    label: string | null = null;
    id: number | null = null;
    widthPx: number = 1;
    heightPx: number = 1;
    f: number = 0.0;
    cx: number = 0.0;
    cy: number = 0.0;
    k1: number = 0.0;
    k2: number = 0.0;
    k3: number = 0.0;
    k4: number = 0.0;
    p1: number = 0.0;
    p2: number = 0.0;

    prop_pixel_width: number = 0.0;
    prop_pixel_height: number = 0.0;
    prop_focal_length: number = 0.0;
}

export class ttMetashapeData {
    label: string;
    transform: THREE.Matrix4;
    scale: number;
    cameras: {label: string, transform: THREE.Matrix4, sensorId: number, componentId: number}[];
    sensors: ttMetashapeSensor[];


    constructor(label: string, transform: THREE.Matrix4, scale: number,
                cameras: {label: string, transform: THREE.Matrix4, sensorId: number, componentId: number}[],
                sensors: ttMetashapeSensor[]) {
        this.label      = label;
        this.transform  = transform;
        this.scale      = scale;
        this.cameras    = cameras;
        this.sensors    = sensors;
    };
};

function convertStr(value: string | null) {
    if (value == null)
        return false;
    if (value.toLowerCase() === "true")
        return true;
    if (value.toLowerCase() === "false")
        return false;
    if (!isNaN(Number(value))) {
        if (Number.isInteger(parseFloat(value)))
            return parseInt(value, 10);
        return parseFloat(value);
    }
    return value;
}

/**
 * @returns {ttMetashapeData}
 */
export async function ttMetashapeParse(projectName: string) : Promise<ttMetashapeData> {
    //TODO: this will come directly from the server later on
    const resp = await fetch(`/models/${projectName}/cameras.xml`);
    const str = await resp.text();

    const parser = new DOMParser();
    const xml = parser.parseFromString(str, "text/xml");
    const msComponentTransform = xml.getElementsByTagName('components')[0]
        .getElementsByTagName("component")[0]
        .getElementsByTagName("transform")[0];
    const msOrigin = msComponentTransform
        .getElementsByTagName("translation")[0]
        .innerHTML
        .split(' ')
        .map(item => parseFloat(item));
    const msOriginRotation = msComponentTransform
        .getElementsByTagName("rotation")[0]
        .innerHTML
        .split(' ')
        .map(item => parseFloat(item));
    const msScale = parseFloat(msComponentTransform.getElementsByTagName("scale")[0].innerHTML);
    let msOriginRotationTfx = new THREE.Matrix3();
    msOriginRotationTfx.elements = msOriginRotation;
    msOriginRotationTfx.transpose(); //row/column is switched when it's input to functions and data layout....
    let msTfx = new THREE.Matrix4();
    msTfx.setFromMatrix3(msOriginRotationTfx);
    msTfx.setPosition(new THREE.Vector3(msOrigin[0], msOrigin[1], msOrigin[2]));
    const msCameras = xml.getElementsByTagName("cameras")[0].children;
    const msCameraData = [];
    for (let msCamera of msCameras) {
        let tfx = new THREE.Matrix4();
        tfx.elements = msCamera.getElementsByTagName("transform")[0].innerHTML
            .split(' ').map(item => parseFloat(item));
        tfx.transpose();
        tfx.elements[12] *= msScale;
        tfx.elements[13] *= msScale;
        tfx.elements[14] *= msScale;
        const cameraD = {
            label: msCamera.getAttribute("label"),
            transform: tfx,
            sensorId: Number(msCamera.getAttribute("sensor_id")),
            componentId: Number(msCamera.getAttribute("component_id")),
        };
        msCameraData.push(cameraD);
    }

    let sensors = [];
    const msSensors = xml.getElementsByTagName('sensors')[0].children;
    for (let msSensorData of msSensors) {
        const resolution = msSensorData.getElementsByTagName('resolution')[0];
        let sensor = new ttMetashapeSensor;
        sensor.id = Number(msSensorData.getAttribute('id'));
        sensor.label = msSensorData.getAttribute('label');
        sensor.widthPx = Number(resolution.getAttribute('width'));
        sensor.heightPx = Number(resolution.getAttribute('height'));
        const calib = msSensorData.getElementsByTagName('calibration')[0];
        sensor.f  = parseFloat(calib.getElementsByTagName('f')[0].innerHTML);
        sensor.cx = parseFloat(calib.getElementsByTagName('cx')[0].innerHTML);
        sensor.cy = parseFloat(calib.getElementsByTagName('cy')[0].innerHTML);
        sensor.k1 = 1.0;
        sensor.k2 = 1.0;
        sensor.k3 = 1.0;
        sensor.k4 = 1.0;
        if (calib.getElementsByTagName('k1').length)
            sensor.k1 = parseFloat(calib.getElementsByTagName('k1')[0].innerHTML);
        if (calib.getElementsByTagName('k2').length)
            sensor.k2 = parseFloat(calib.getElementsByTagName('k2')[0].innerHTML);
        if (calib.getElementsByTagName('k3').length)
            sensor.k3 = parseFloat(calib.getElementsByTagName('k3')[0].innerHTML);
        if (calib.getElementsByTagName('k4').length)
            sensor.k4 = parseFloat(calib.getElementsByTagName('k4')[0].innerHTML);
        sensor.p1 = parseFloat(calib.getElementsByTagName('p1')[0].innerHTML);
        sensor.p2 = parseFloat(calib.getElementsByTagName('p2')[0].innerHTML);
        let sensorProps = msSensorData.getElementsByTagName('property');
        for (let sensorProp of sensorProps) {
            const sensorPropName = sensorProp.getAttribute('name');
            sensor[`prop_${sensorPropName}`] = convertStr(sensorProp.getAttribute('value'));
        }
        sensors.push(sensor);
    }
    return new ttMetashapeData("test", msTfx, msScale, msCameraData, sensors);
}

