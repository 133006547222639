import * as THREE from 'three';

import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
export const lineMaterialModel = [
    new LineMaterial({
        color: 0xFFFFFF,
        linewidth: 0.014,
        vertexColors: false,
        dashed: false,
        alphaToCoverage: true,
        worldUnits: true,
    }),
    new LineMaterial({
        color: 0x0000FF,
        linewidth: 0.014,
        vertexColors: false,
        dashed: false,
        alphaToCoverage: true,
        worldUnits: true,
    }),
    new LineMaterial({
        color: 0xFFFF00,
        linewidth: 0.014,
        vertexColors: false,
        dashed: false,
        alphaToCoverage: true,
        worldUnits: true,
    }),
    new LineMaterial({
        color: 0xFF0000,
        linewidth: 0.014,
        vertexColors: false,
        dashed: false,
        alphaToCoverage: true,
        worldUnits: true,
    }),
    new LineMaterial({
        color: 0x22CF22,
        linewidth: 0.016,
        vertexColors: false,
        dashed: false,
        alphaToCoverage: true,
        worldUnits: true,
    }),
];

export const lineMaterialImage = [
    new LineMaterial({
        color: 0xFFFFFF,
        linewidth: 0.001,
        vertexColors: false,
        dashed: false,
        alphaToCoverage: true,
        worldUnits: false,
    }),
    new LineMaterial({
        color: 0x0000FF,
        linewidth: 0.001,
        vertexColors: false,
        dashed: false,
        alphaToCoverage: true,
        worldUnits: false,
    }),
    new LineMaterial({
        color: 0xFFFF00,
        linewidth: 0.001,
        vertexColors: false,
        dashed: false,
        alphaToCoverage: true,
        worldUnits: false,
    }),
    new LineMaterial({
        color: 0xFF0000,
        linewidth: 0.001,
        vertexColors: false,
        dashed: false,
        alphaToCoverage: true,
        worldUnits: false,
    }),
    new LineMaterial({
        color: 0x22CF22,
        linewidth: 0.001,
        vertexColors: false,
        dashed: false,
        alphaToCoverage: true,
        worldUnits: true,
    }),
];
