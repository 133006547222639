import { useFirebaseAuth } from 'vuefire'
import * as THREE from 'three';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { Viewer } from './ttViewer';
import { ttMetashapeSensor } from './ttMetashapeParse';
import { Polyline } from './ttObject';

export function clamp(num: number, min: number, max: number): number {
    return Math.min(Math.max(num, min), max);
}

export function toNormalizedScreenCoord(x: number, y: number, canvas: HTMLElement): THREE.Vector2 {
    const mouse = new THREE.Vector2();
    const rect = canvas.getBoundingClientRect();
    mouse.x =  ((x - rect.left) / canvas.clientWidth ) * 2 - 1;
    mouse.y = -((y - rect.top ) / canvas.clientHeight) * 2 + 1;
    return mouse;
}

export function raycastPointOnMainModel(viewer: Viewer, event: MouseEvent): THREE.Intersection | null {
    if (!viewer.canvas)
        return null;
    const mouse = toNormalizedScreenCoord(event.clientX, event.clientY, viewer.canvas);
    viewer.raycaster.setFromCamera(mouse, viewer.camera);
    const intersects = viewer.raycaster.intersectObjects(viewer.models, true);
    if (intersects.length > 0) {
        return intersects[0];
    }
    return null;
}

export function raycastPointOnImage(viewer: Viewer, event: MouseEvent): THREE.Intersection | null {
    if (!viewer.imageCanvas)
        return null;
    const mouse = toNormalizedScreenCoord(event.clientX, event.clientY, viewer.imageCanvas);
    viewer.raycaster.setFromCamera(mouse, viewer.orthoCamera);
    const intersects = viewer.raycaster.intersectObjects([viewer.imageMesh], true);
    if (intersects.length === 0)
        return null;
    return intersects[0];
}

export function projectLineOnImage(line: Polyline, tfx: THREE.Matrix4, sensor: ttMetashapeSensor, clipBack: boolean): LineGeometry|null {
    const point = new THREE.Vector3();
    const unprojectedPointsData: number[] = [];
    for (let i = 0; i < line.count(); i++) {
        line.vertexAt(i, point);
        point.applyMatrix4(tfx);
        if (clipBack && point.z < 0.0) {
            return null;
        }
        let x =  point.x / point.z;
        let y = -point.y / point.z;
        let r = Math.sqrt(x * x + y * y);
        let xp = x +
            x * sensor.k1 * Math.pow(r, 2) +
            x * sensor.k2 * Math.pow(r, 4) +
            x * sensor.k3 * Math.pow(r, 6) +
            x * sensor.k4 * Math.pow(r, 8) +
            (sensor.p1 * (r * r + 2 * x * x) + 2 * sensor.p2 * x * y);
        let yp = y +
            y * sensor.k1 * Math.pow(r, 2) +
            y * sensor.k2 * Math.pow(r, 4) +
            y * sensor.k3 * Math.pow(r, 6) +
            y * sensor.k4 * Math.pow(r, 8) +
            (sensor.p2 * (r * r + 2 * y * y) + 2 * sensor.p1 * x * y);
        let u = sensor.widthPx  + sensor.cx + xp * sensor.f;
        let v = sensor.heightPx - sensor.cy + yp * sensor.f;

        unprojectedPointsData.push(u);
        unprojectedPointsData.push(v);
        unprojectedPointsData.push(10.0);
    }
    const geometry = new LineGeometry();
    geometry.setPositions(unprojectedPointsData);
    return geometry;
}

export function unproject(viewer: Viewer, imageX: number, imageY: number): THREE.Vector3|null {
    if (!viewer.metashapeData)
        return null;

    const camera = viewer.metashapeData.cameras[viewer.cameraIndex];
    const tfx = new THREE.Matrix4();
    tfx.copy(viewer.metashapeData.transform);
    tfx.multiply(camera.transform);
    const sensor = viewer.metashapeData.sensors.find(e => e.id === camera.sensorId);
    if (!sensor)
        return null;
    const xx = (imageX - sensor.widthPx - sensor.cx) / sensor.f;
    const yy = -(imageY - sensor.heightPx + sensor.cy) / sensor.f;
    const pos = new THREE.Vector3();
    const point = new THREE.Vector3(xx, yy, 1.0);
    pos.setFromMatrixPosition(tfx);
    point.applyMatrix4(tfx);
    const dir = new THREE.Vector3();
    dir.subVectors(point, pos);

    const raycaster = new THREE.Raycaster()
    raycaster.set(pos, dir);
    const intersects = raycaster.intersectObjects(viewer.models, true);
    if (intersects.length === 0)
        return null;
    return intersects[0].point;
}

export async function onLogout() {
    const auth = useFirebaseAuth();
    auth.signOut().then(() => {
        console.log("Sign Out completed");
        router.push("/");
    })
    .catch((error) => console.log(error));
};

export async function onProfile() {
    console.log("On profile");
    router.push("/");
};

